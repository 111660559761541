<template>
  <div class="mt-4"></div>
  <DropdownList
      :items="countries"
      keyField="code3"
      displayField="country"
      labelText="Select a country in the list below"
      :defaultLabel="mapIso2ToIso3[props.country]"
      iconClass="fa-solid fa-globe me-3"
      :defaultItem="countryNamesISO3[mapIso2ToIso3[props.country]]"
      @itemSelected="onCountrySelected"
  />
  <div class="d-flex" style="width: 100%; gap: 20px;">
      <div v-if="graphData.length > 0" style="width: 50%;" class="border bg-white p-3">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p style="font-weight: bold; font-size: 18px;" class="pb-0">Country Risk Premium</p>
            <p class="pb-0">TAC RRECC, in basis points</p>
          </div>
          <div class="d-flex flex-column align-items-end" style="width: auto;">
            <div
              v-for="(item, index) in legends"
              :key="index"
              class=""
            >
              <LegendElem
                :label="item.label"
                :color="item.color"
                :_width="item.wdith"
                :_height="item.height"
                :fontSize="item.fontSize"
                :rounded="item.rounded"
                :borderRadius="item.borderRadius"
              />
            </div>
          </div>
        </div>
          <CountryDetailsHisto :graphData="graphData"/>
      </div>
      <div style="width: 50%; height: 100%;" class="border bg-white p-3">
          <div class="border bg-white p-3">
              <h3 style="font-weight: bold;">Economic Risk Ratings (Average)</h3>
              <p>From 0 (lowest risk) to 100 (highest risk)</p>
              <hr />
              <div v-for="(item, index) in tabEcoRisk" :key="index" class="d-flex justify-content-between">
                  <p style="font-weight: bold;" v-if="index === 0">{{ item.name }}</p>
                  <p v-else>{{ item.name }}</p>
                  <p>{{ item.value }}</p>
              </div>
          </div>
          <div class="border bg-white p-3 mt-3">
              <h3 style="font-weight: bold;">Political Risk</h3>
              <p>From 0 (lowest risk) to 100 (highest risk)</p>
              <hr />
              <div v-for="(item, index) in tabPolRisk" :key="index" class="d-flex justify-content-between">
                  <p style="font-weight: bold;" v-if="index === 0">{{ item.name }}</p>
                  <p v-else>{{ item.name }}</p>
                  <p>{{ item.value }}</p>
              </div>
          </div>
      </div>
  </div>
  <div class="d-flex mt-3" style="width: 100%; gap: 20px;">
    <div style="width: 50%;" class="border bg-white p-3">
      <h3 style="font-weight: bold;">Economic & Financial Risk Scores by component</h3>
      <p>From 0 (lowest risk) to 100 (highest risk)</p>
      <RadarChart
          v-if="Object.keys(ecoRadar).length > 0"
            :chartData="ecoRadar"
            categoryField="name"
            valueField="value"
            :maxY="100"
            id="chartdiv2"
          />
      </div>
      <div style="width: 50%;" class="border bg-white p-3">
        <h3 style="font-weight: bold;">Political & Governance Risk Ratings by component</h3>
        <p>From 0 (lowest risk) to 100 (highest risk)</p>
        <RadarChart
            v-if="Object.keys(polRadar).length > 0"
          :chartData="polRadar"
          categoryField="name"
          valueField="value"
          :maxY="100"
          id="chartdiv1"
        />
      </div>
  </div>
  <div class="pb-2">
  <div class="border mt-3 mb-4">
    <h3 class="m-3 mb-0" style="font-weight: bold;">Economic Data and Forecast</h3>
    <div class="table-responsive ps-3 pe-3" style="max-height: 600px; overflow-y: auto;">
      <table class="table custom-table mt-4" style="table-layout: fixed; width: 100%;">
        <thead class="sticky-header">
          <tr>
            <th v-for="header in headers" :key="header.text" @click="header.sortable ? sortByCriteria(header.text) : null"
                :style="header.sortable ? { cursor: 'pointer' } : null"
                :class="{'name-column': header.text === ''}"
                class="text-center">
              <div style="display: block; text-align: center;">
                <i :class="header.icon" class="me-2 ms-2 text-center"></i>
                <div class="mt-3" style="font-size: 13px;">
                  {{ header.text }}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody style="height: 400px; overflow-y: auto;">
          <tr class="items" v-for="(item, index) in tabWeo" :key="index">
            <td class="text-center name-column">{{ item.name }}</td>
            <td v-for="year in headers.slice(1)" :key="year.text" class="text-center" style="font-size: 15px; width: 6.36%;">
              {{ item[year.text] }}
            </td>
          </tr>
        </tbody>
      </table>
      <p style="font-size: 8px;">Sources: TAC ECONOMICS, IMF/WEO, World Bank</p>
    </div>
  </div>
</div>

</template>

<script setup>
/* eslint-disable */
import { defineProps, onMounted, ref } from 'vue';
import DropdownList from '../UI/Dropdown/DropdownList.vue';
import CountryDetailsHisto from './RiskCountryDetailsHisto.vue';
import RadarChart from './RiskCountryDetailsRadar.vue';
import api from '@/services/api'
import { mapIso2ToIso3, countryNamesISO3 } from '../../utils/CountryMapping';
import LegendElem from '../UI/LegendElem.vue';

const tabEcoRisk = ref([]);
const tabPolRisk = ref([]);
const graphData = ref([]);
const tabWeo = ref([]);
const polRadar = ref([]);
const ecoRadar = ref([]);

const props = defineProps({
  data: {
      type: Array,
      required: true
  },
  country: {
      type: String,
      required: true
  }
});

const legends = [
  { label: "Economic Risk", color: "#a12318", wdith: 21, height: 21, fontSize: 10, rounded: false, borderRadius: 0 },
  { label: "Political Risk", color: "#142556", wdith: 21, height: 21, fontSize: 10, rounded: false, borderRadius: 0 },
  { label: "Commodity exports dependence premium", color: "#9c93f7", wdith: 21, height: 21, fontSize: 10, rounded: false, borderRadius: 0 },
  { label: "Repeated debt restructurings premium", color: "#539a79", wdith: 21, height: 21, fontSize: 10, rounded: false, borderRadius: 0 },
  { label: "War in Ukraine Risk Premium", color: "#d8d8d8", wdith: 21, height: 21, fontSize: 10, rounded: false, borderRadius: 0 },
  { label: "Crisis signal premium", color: "#edb847", wdith: 21, height: 21, fontSize: 10, rounded: false, borderRadius: 0 },
  { label: "Country Risk Premium", color: "#3c4340", wdith: 21, height: 2, fontSize: 10, rounded: false, borderRadius: 0 },
];

const selectCountry = ref(mapIso2ToIso3[props.country]);

const headers = ref([{ text: "" }]);

async function fetchData() {
  console.log(selectCountry.value);
  await api.get("/data/loadviews/countryrisk/" + selectCountry.value)
    .then(response => {
      tabEcoRisk.value = response.data.data.tab_ecorisk;
      graphData.value = response.data.data.graphCompCRP;
      polRadar.value = response.data.data.radar_pol_test;
      ecoRadar.value = response.data.data.radar_eco_test;
      tabWeo.value = response.data.data.tab_weo;

      if (tabWeo.value.length > 0) {
        const years = Object.keys(tabWeo.value[0]).filter(key => !isNaN(parseInt(key)));
        headers.value = [{ text: "" }, ...years.map(year => ({ text: year }))];
      }

      tabEcoRisk.value.forEach((item) => {
        if (item.value === null) {
          item.value = "N/A";
        }
      });
      tabPolRisk.value = response.data.data.tab_polrisk;
    })
    .catch((error) => {
      console.error(error);
    });
}
function onCountrySelected(countryCode) {
  selectCountry.value = countryCode;
  fetchData();
}

const countries = ref(props.data);

onMounted(() => {
  fetchData();
});

</script>

<style scoped>
.name-column {
  width: 300px;
  max-width: 280px;
  min-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
}

td {
  word-wrap: break-word !important;
  white-space: normal !important;
  overflow: hidden !important;
}
</style>
