<template>
  <div class="row row-cols-1 row-cols-md-3 row-cols-xl-5 row-cols-sm-3">
  <div class="block col" v-for="item in dashboards" :key="item.id">
    <a v-bind:href="'/dashboards/' + item.id">
      <div class="row">
        <div class="col">
          <div class="card justify-content-center align-items-center border shadow" style="height: 350px; width: 100%;">
            <img class="card-img-top" :src="'/img/dashboards/' + (item.picture).slice(0, -4) + '.jpg'" alt="Image Dashboard" height="150">
            <div class="card-body">
              <h4 class="name pt-3">{{ item.name }}</h4>
              <div class="description text-muted" v-html="item.description"></div>
            </div>
          </div>
        </div>
      </div>
    </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dashboards: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.blocDashboardsList {
  padding: 0 10%;
}

.block {
  transition: 0.3s ease-in-out;
  padding: 1em;
}

.block:hover {
  transform: scale(1.05);
}

.block:active {
  transform: scale(1.02);
}

.opacity {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.547);
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  text-align: center;
}

.text p {
  margin: 0;
}

.text p a {
  text-decoration: underline;
}

.opacity .text {
  color: white;
  font-size: 1.2em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: rgba(255, 0, 0, 0.255);
  width: 100%;
  height: 150px;
  padding-top: 1em;
  transition: all 0.2s ease-in-out;
}

.card-body {
  height: 140px;
  transition: all 0.2s ease-in-out;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
}

.card {
  border-radius: 10px;
}

.card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.description {
  font-size: 14px;
}


@media screen and (max-width: 1400px) {
  .opacity .text {
    font-size: 1em;
  }
}

@media screen and (max-width: 1200px) {
  .description {
    font-size: 13px;
  }
}

@media screen and (max-width: 991px) {
  .name {
    font-size: 12px;
  }

  .description {
    font-size: 11px;
  }

  .opacity .text {
    font-size: 1em;
  }
}

@media screen and (max-width: 767px) {
  .name {
    font-size: 15px;
  }

  .description {
    font-size: 12px;
  }

  .opacity .text {
    font-size: 1.4em;
  }
}
</style>