<template>
    <div class="">
      <div :id="props.id" style="width: 100%; height: 400px;"></div>
    </div>
  </template>
  
  <script setup>
  /* eslint-disable */
  import { onMounted, onBeforeUnmount, defineProps, watch } from "vue";
  import * as am5 from "@amcharts/amcharts5";
  import * as am5xy from "@amcharts/amcharts5/xy";
  import * as am5radar from "@amcharts/amcharts5/radar";
  import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

  const props = defineProps({
    chartData: {
      type: Array,
      required: true,
    },
    categoryField: {
      type: String,
      required: true,
    },
    valueField: {
      type: String,
      required: true,
    },
    maxY: {
      type: Number,
      default: null,
    },
    id: {
      type: String,
    },
  });
  
  let root;
  
  const createChart = () => {
    am5.ready(() => {
      root = am5.Root.new(props.id);
  
      root.setThemes([am5themes_Animated.new(root)]);
  
      let chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
          panX: false,
          panY: false,
          layout: root.verticalLayout,
          paddingLeft: 0,
        })
      );
  
      const cursor = chart.set(
        "cursor",
        am5radar.RadarCursor.new(root, {
          behavior: "zoomX",
        })
      );
      cursor.lineY.set("visible", false);
  
      const xRenderer = am5radar.AxisRendererCircular.new(root, {});

      xRenderer.labels.template.setAll({
        radius: 20,
      });
        
      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0,
          categoryField: props.categoryField,
          renderer: xRenderer,
        })
      );
  
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          renderer: am5radar.AxisRendererRadial.new(root, {}),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      yAxis.get("renderer").labels.template.setAll({
        radius: 10,
        fontSize: 12,
      });
  
      xAxis.get("renderer").labels.template.setAll({
        oversizedBehavior: "wrap",
        maxWidth: 150,
        ellipsis: "...",
        fontSize: 12,
      });

      xAxis.get("renderer").grid.template.setAll({
        minGridDistance: 20,
      });
  
      if (props.maxY) {
        yAxis.set("max", props.maxY);
      }

      var legend = chart.children.push(am5.Legend.new(root, {
        y: 0,
        x: am5.percent(0),
        nameField: "name",
        fillField: "fill",
        strokeField: "fill",
        position: "absolute",
        layout: root.verticalLayout 
      }));


      legend.itemContainers.template.setAll({
        paddingTop: 5,
        paddingBottom: 0,
        marginTop: 0,
        marginBottom: 0
      });
  
      const series = chart.series.push(
        am5radar.RadarLineSeries.new(root, {
          name: "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: props.valueField,
          categoryXField: props.categoryField,
          tooltip: am5.Tooltip.new(root, {
            labelText: "{valueY}",
          }),
        })
      );

      series.strokes.template.setAll({
        strokeWidth: 5,
        stroke: am5.color("#85cdf7")
      });

      const series2 = chart.series.push(
         am5radar.RadarLineSeries.new(root, {
           name: "Series",
           xAxis: xAxis,
           yAxis: yAxis,
           valueYField: props.valueField,
           categoryXField: props.categoryField,
           stroke: am5.color("#c0c0c0"),
           fill: am5.color("#c0c0c0"),
           fillOpacity: 0.3,
           tooltip: am5.Tooltip.new(root, {
             labelText: "{valueY}",
           }),
         })
      );

      series2.strokes.template.setAll({
        strokeWidth: 2,
      });



      legend.data.push (
        {
          "name": props.chartData.country.name,
          "fill": "#85cdf7"
        }
      );

      legend.data.push (
        {
          "name": props.chartData.region.name,
          "fill": "#c0c0c0"
        }
      );
  
      series.strokes.template.setAll({
        strokeWidth: 5,
        stroke: am5.color("#c0c0c0")
      });
      // console.log(props.chartData.region);
      series.data.setAll(props.chartData.country.data);
      series2.data.setAll(props.chartData.region.data);
      xAxis.data.setAll(props.chartData.country.data);
  
      series.appear(1000);
      chart.appear(1000, 100);
    });
  };

  watch(() => props.chartData, () => {
    if (root) {
      root.dispose();
    }
    createChart();
  });
  
  onMounted(() => {
    createChart();
  });
  
  onBeforeUnmount(() => {
    if (root) {
      root.dispose();
    }
  });
  </script>
  
  <style scoped>
  </style>
  