<template>
    <div id="app">
      <Apidoc />
    </div>
  </template>
  
  <script>
  import Apidoc from '@/components/apidoc/Apidoc.vue'
  export default {
  props: { id: { type: String } },
  components: {
    Apidoc
  }
}
  </script>
  