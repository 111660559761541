<template>
  <Transition name="fade">
    <div v-if="dropdownOpen" class="dropdown-background">
      <div style="padding: 3rem;">
        <a
          v-for="(item, index) in menuItems"
          :key="index"
          class="dropdown-item"
          @click="item.action"
          :href="item.href"
        >
        <img v-if="item.iconType === 'image'" :src="require(`@/assets/${item.icon}`)" class="icon-class me-3" alt="icon" />
        <i v-else :class="item.icon" class="fa-xl me-3"></i>
          <span v-if="item.isBold" class="fw-bold">{{ item.text }}</span>
          <span v-else>{{ item.text }}</span>
        </a>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { defineProps, toRef } from 'vue';

const props = defineProps({
  menuItems: Array,
  trigger: Boolean
});

const dropdownOpen = toRef(props, 'trigger');

const menuItems = toRef(props, 'menuItems');
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.dropdown-background {
  background-color: rgb(255, 255, 255);
  position: absolute;
  width: 100%;
  border-bottom: 2px solid #ececec;
}

.dropdown-item {
  padding: 10px; 
  width: 50%;
  margin-bottom: 10px;
  cursor: pointer;
  color: black;
  border-radius: 5px;
}

a {
  color: black
}

a:focus {
  background-color: transparent;
}

</style>
