<template>
    <h2 class="pt-3">
      <i class="fa-solid fa-bolt me-3"></i>Alert GPT - Alert on GeoPolitical Tensions
    </h2>
    <hr />  
    <p>Select a country in the list below</p>
    <div class="dropdown mb-3">
      <button
        class="dropdown-toggle"
        type="button"
        id="periodicityDropdown"
        data-bs-toggle="dropdown"
        style="font-weight: normal;"
      >
        <i class="fa-solid fa-globe me-3"></i>{{ country ? country : 'Select a country' }}
      </button>
      <ul class="dropdown-menu" style="max-height: 400px; overflow-y: auto; z-index: 1; position: relative;">
        <div class="mb-2 inputs d-flex ps-2 pe-2 sticky-search" style="align-items: center;">
          <i style="color: rgba(220, 220, 220, 0.75)" class="fa fa-search"></i>
          <input
            type="text"
            class="form-control"
            placeholder="Search country"
            v-model="searchTerm"
            @input="onSearchInput"
          />
        </div>
        <template v-for="countryH in filteredCountries" :key="countryH.country">
          <div class="country" @click="selectCountry(countryH.country)">
            <label style="margin-left: 10px; cursor: pointer;" :for="countryH.country" @click.stop>
              <span @click="selectCountry(countryH.country)" style="font-size: 18px; margin-left: 15px;">
                {{ countryH.country }}
              </span>
            </label>
          </div>
        </template>
      </ul>
    </div>
    <div class="table-responsive">
      <table class="table custom-table">
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.value"
              class="headers"
              :class="{ 'name-column': header.value === 'name' }"
              scope="col"
              @click="header.sortable ? $emit('sort', header.value) : null"
              :style="header.sortable ? { cursor: 'pointer' } : null"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
            <tr v-for="col in data" :key="col.text" class="items">
                <td style="align-items: center; text-align: center" ><p v-if="buzzData.length > 0" style="margin-top: 75px;">{{ col.text }}</p></td>
                <td>
                  <div v-if="col.text == 'Buzz' && buzzData.length > 0">
                      <AlertGraph :key="country + '-buzz'" :id="col.id" :data="buzzData"/>
                  </div>
                  <div v-else-if="col.text == 'Intensity' && sentimentData.length > 0">
                      <AlertGraph :key="country + '-intensity'" :id="col.id" :data="sentimentData"/>
                  </div>
                  <div v-else-if="col.text == 'Violence' && violenceData.length > 0" >
                      <AlertGraph :key="country + '-violence'" :id="col.id" :data="violenceData"/>
                  </div>
                  <div v-else-if="col.text == 'Violence/Buzz' && viobuzzData.length > 0" >
                      <AlertGraph :key="country + '-violence_buzz'" :id="col.id" :data="viobuzzData"/>
                  </div>
                  <div style="display: flex; justify-content: center;" v-else>
                      <div class="no-data-message">No data found</div>
                  </div>
                </td>
                <td style="align-items: center; text-align: center">
                  <p v-if="col.text == 'Buzz'  && buzzData.length > 0" style="margin-top: 75px; font-size: 20px; font-weight: bold;">{{ buzzLastValue.myvalue.toPrecision(3) }} <br> {{ buzzLastValue.timestamp }}</p>
                  <p v-if="col.text == 'Intensity'  && sentimentData.length > 0" style="margin-top: 75px; font-size: 20px; font-weight: bold;">{{ sentimentLastValue.myvalue.toPrecision(3) }} <br> {{ sentimentLastValue.timestamp }}</p>
                  <p v-if="col.text == 'Violence'  && violenceData.length > 0" style="margin-top: 75px; font-size: 20px; font-weight: bold;">{{ violenceLastValue.myvalue.toPrecision(3) }} <br> {{ violenceLastValue.timestamp }}</p>
                  <p v-if="col.text == 'Violence/Buzz'  && viobuzzData.length > 0" style="margin-top: 75px; font-size: 20px; font-weight: bold;">{{ viobuzzLastValue.myvalue.toPrecision(3) }}% <br> {{ viobuzzLastValue.timestamp }}</p>
                </td>
            </tr>
            </tbody>
      </table>
    </div>
  </template>
  
<script setup>
/* eslint-disable */
import SpectrumChart from "../../components/SpectrumChart.vue";
import AlertGraph from "../../components/AlertGraph.vue";
import { onMounted, ref, computed, watch } from "vue";
import api from '@/services/api'


const country = ref("World");
const Iso3 = ref("WLD");
const searchTerm = ref("");
const buzzData = ref([]);
const sentimentData = ref([]);
const violenceData = ref([]);
const viobuzzData = ref([]);
const buzzLastValue = ref(0);
const sentimentLastValue = ref(0);
const violenceLastValue = ref(0);
const viobuzzLastValue = ref(0);

const props = defineProps({
  data: {
    type: Array,
    required: true,
  }
});

const countries = ref(props.data);

const selectCountry = (countrySelected) => {
  country.value = countrySelected;
  Iso3.value = countries.value.find((c) => c.country === countrySelected).code3;
};

const getLastValue = (data) => {
  if (data.length > 0) {
    return {
      myvalue: data[data.length - 1].myvalue,
      timestamp: data[data.length - 1].timestamp
    }
  }
  return 0;
};

const fetchData = async () => {
await api.get("/data/loadviews/geopol/" + Iso3.value)
  .then(response => {
    if (response.data && response.data.data) {
      buzzData.value = response.data.data.buzz || [];
      buzzLastValue.value = getLastValue(buzzData.value);

      sentimentData.value = response.data.data.sentiment || [];
      sentimentLastValue.value = getLastValue(sentimentData.value);
    
      violenceData.value = response.data.data.violence || [];
      violenceLastValue.value = getLastValue(violenceData.value);

      viobuzzData.value = response.data.data.violence_buzz || [];
      viobuzzLastValue.value = getLastValue(viobuzzData.value);
      
      const resumeData = response.data.data.resume || [];

      const mergedBuzzData = buzzData.value.map(buzzItem => {
        const matchingResume = resumeData.find(resumeItem => resumeItem.timestamp === buzzItem.timestamp);

        return {
          timestamp: buzzItem.timestamp,
          myvalue: buzzItem.myvalue,
          summary: matchingResume ? matchingResume.value : null
        };
      });

      const mergedSentimentData = sentimentData.value.map(sentimentItem => {
        const matchingResume = resumeData.find(resumeItem => resumeItem.timestamp === sentimentItem.timestamp);

        return {
          timestamp: sentimentItem.timestamp,
          myvalue: sentimentItem.myvalue,
          summary: matchingResume ? matchingResume.value : null
        };
      });

      const mergedViolenceData = violenceData.value.map(violenceItem => {
        const matchingResume = resumeData.find(resumeItem => resumeItem.timestamp === violenceItem.timestamp);

        return {
          timestamp: violenceItem.timestamp,
          myvalue: violenceItem.myvalue,
          summary: matchingResume ? matchingResume.value : null
        };
      });

      const mergedViobuzzData = viobuzzData.value.map(viobuzzItem => {
        const matchingResume = resumeData.find(resumeItem => resumeItem.timestamp === viobuzzItem.timestamp);

        return {
          timestamp: viobuzzItem.timestamp,
          myvalue: viobuzzItem.myvalue,
          summary: matchingResume ? matchingResume.value : null
        };
      });

      violenceData.value = mergedViolenceData;
      sentimentData.value = mergedSentimentData;
      buzzData.value = mergedBuzzData;
      viobuzzData.value = mergedViobuzzData;
      
    } else {
      buzzData.value = [];
      sentimentData.value = [];
      violenceData.value = [];
      viobuzzData.value = []; 
    }
  })
  .catch((error) => {
    console.error(error);
  });
};

const headers = [
  { text: "Indicator" },
  { text: "Timeline" },
  { text: "Last Value" },
];

const data = [
  { text: "Buzz", id: "buzz" },
  { text: "Intensity", id: "intensity" },
  { text: "Violence", id: "violence" },
  { text: "Violence/Buzz", id: "violence_buzz" },
];

const filteredCountries = computed(() => {
  countries.value.sort((a, b) => a.country.localeCompare(b.country));
  return countries.value.filter((country_n) =>
    country_n.country.toLowerCase().includes(searchTerm.value.toLowerCase())
  );
});

watch(() => Iso3.value, () => {
  fetchData();
});

onMounted(() => {
  fetchData();
});
</script>
  
<style scoped>

.dropdown-menu {
  padding: 0 !important;
}

.sticky-search {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
}

.no-data-message {
  font-size: 16px;
  text-align: center;
  color: #999;
  padding: 20px;
}

.form-control {
  border: none;
  height: 45px;
  border-radius: 0px;
  border-bottom: 1px solid #eee;
}

.form-control:focus {
color: #495057;
background-color: #fff;
border-color: #eee;
outline: 0;
box-shadow: none;
border-bottom: 1px solid blue;
}

.country:hover {
  background-color: #f8f9fa;
}

.country {
  cursor: pointer;
}

.dropdown button {
  border: 1px solid #ececec;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  font-weight: bold;
  font-size: 1.2em;
  background-color: rgb(255, 255, 255);
}

.dropdown-toggle::after {
  margin-left: 15px;
}

.custom-table {
  table-layout: auto;
}

.custom-table th, .custom-table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-table thead tr, .custom-table thead th {
  border-top: none;
  border-bottom: none !important;
}

.custom-table tbody th, .custom-table tbody td {
  color: #777;
  font-weight: 400;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: 300;
}
  
.custom-table tbody th small, .custom-table tbody td small {
  color: #b3b3b3;
  font-weight: 300;
}

.custom-table tbody .persons {
  padding: 0;
  margin: 0;
}
    
.custom-table tbody .persons li {
  padding: 0;
  margin: 0 0 0 -15px;
  list-style: none;
  display: inline-block;
}

.custom-table tbody .persons li a {
  display: inline-block;
  width: 36px;
}

.custom-table tbody .persons li a img {
  border-radius: 50%;
  max-width: 100%;
}

.custom-table tbody tr th, .custom-table tbody tr td {
  position: relative;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}
  
.custom-table tbody tr th:before, .custom-table tbody tr th:after, .custom-table tbody tr td:before, .custom-table tbody tr td:after {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
	content: "";
	left: 0;
	right: 0;
	position: absolute;
	height: 1px;
	background: #bfbfbf;
	width: 100%;
	opacity: 0;
	visibility: hidden;
}

.custom-table tbody tr th:before, .custom-table tbody tr td:before {
	top: -1px;
}

.custom-table tbody tr th:after, .custom-table tbody tr td:after {
	bottom: -1px;
}

.custom-table tbody tr:hover th, .custom-table tbody tr:hover td {
  background: rgba(0, 0, 0, 0.03);
}

.custom-table tbody tr:hover th:before, .custom-table tbody tr:hover th:after, .custom-table tbody tr:hover td:before, .custom-table tbody tr:hover td:after { 
	opacity: 1;
  visibility: visible;
}

.custom-table tbody tr.active th, .custom-table tbody tr.active td {
  background: rgba(0, 0, 0, 0.03);
}

.custom-table tbody tr.active th:before, .custom-table tbody tr.active th:after, .custom-table tbody tr.active td:before, .custom-table tbody tr.active td:after {    
	opacity: 1;
  visibility: visible;
}

.blocProducts {
    padding: 0 7%;
}
  
#chartdiv {
    width: 100%;
    height: 100px;
}
</style>
  