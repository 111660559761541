<template>
  <div class="card" :style="{ width: cardWidth }">
    <div class="card-body">
      <h5 class="card-title"><strong>{{ date }}</strong></h5>
      <p class="card-text">{{ text }}</p>
      <div v-for="link in links" :key="link.href">
        <a :href="link.href" class="card-link">{{ link.label }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard",
  props: {
    date: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
    cardWidth: {
      type: String,
      default: "25vw",
    },
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 1rem;
}
</style>
