<template>
    <div id="swagger-ui" class="swagger-container"></div>
  </template>
  
  <script>
  import SwaggerUI from "swagger-ui-dist/swagger-ui-bundle";
  
  export default {
    name: "SwaggerUI",
    mounted() {
      SwaggerUI({
        url: "/swagger.json", // Path to the Swagger JSON file
        dom_id: "#swagger-ui",
        presets: [SwaggerUI.presets.apis],
        layout: "BaseLayout",
        deepLinking: true,
        requestInterceptor: (request) => {
            const authHeader = request.headers["Authorization"];
            if (authHeader) {
                request.headers["Authorization"] = `Bearer ${authHeader}`;
            }
            return request;
        }
      });
    }
  };
  </script>
  
  <style>
  /* Add Swagger UI styles */
  @import "swagger-ui-dist/swagger-ui.css";
  
  .swagger-container {
    width: 100%;
    margin: 0 auto;
  }
  </style>
  