<template>
     <div class="blocProducts text-center" style="padding-bottom: 1rem;">
        <div class="" style="width: 100%;">
            <form class="d-flex mb-5 form_search" role="search" action="/search" method="get">
                <div class="input-group input-group-sm ms-3 search">
                <input type="search" class="form-control" name="q" placeholder="Search indicators, countries...">
                <button type="submit"><i class="fa fa-search" aria-label="button to submit the form"></i></button>
                </div>
            </form>
        </div>
        <div class="row d-flex justify-content-center" style="margin-bottom: 5rem;">
            <div class="col-5">
            <div class="mb-2 title"><b>Popular Search</b></div>
            <a href="/search?q=real gdp" class="badge badge-popular">real gdp</a>
            <a href="/search?q=inflation" class="badge badge-popular">inflation</a>
            <a href="/search?q=unemployment" class="badge badge-popular">unemployment</a>
            <a href="/search?q=unemployment united states" class="badge badge-popular">unemployment united states</a>
            <a href="/search?q=esg" class="badge badge-popular">ESG</a>
            <a href="/search?q=country risk premium" class="badge badge-popular">country risk premium</a>
            </div>
            <div class="col-5">
            <div class="mb-2 title"><b>Trending Timeseries</b></div>
            <a href="/data/EIA/BREPUUS/WLD" class="badge badge-popular">Brent Price</a>
            <a href="/data/FRED/UNRATE/USA" class="badge badge-popular">US Unemployment Rate</a>
            <a href="/data/IFS/FIDR_PA_M/FRA" class="badge badge-popular">Deposit Rates in France</a>
            <a href="/data/LTF/CPIYOY_F/FRA" class="badge badge-popular">Long Term Inflation Forecasts for France</a>
            </div>
        </div>
        <hr />
        <h2><strong>Latest News</strong></h2>
        <div class="d-flex justify-content-between mt-5" style="margin-bottom: 5rem;">
            <div v-for="card in cards" :key="card.text">
              <NewsCard :card="card" :text="card.text" :date="card.date" :links="card.links" />
            </div>
        </div>
        <hr />
        <div>
          <h2><strong>Datasets</strong></h2>
          <div class="row row-cols-1 row-cols-md-3 mt-5">
            <div class="col mb-4" v-for="dataset in filteredDatasets" :key="dataset">
      
              <a v-bind:href="'/search?dataset=' + dataset.id">
                <div class="flip-card">
                  <div class="blockDataset card justify-flex-start align-items-center"
                    @mouseover="dataset.hovered = true" @mouseleave="dataset.hovered = false">
                    <h4 class="pt-3" :class="{ 'card-title': true, 'title-hover': dataset.hovered }">{{ dataset.name }}</h4>
                    <hr>
                    <p class="card-subtitle" v-if="dataset.id != dataset.provider_code">{{ dataset.provider_name
                    }}
                    </p>
                    <div class="blockDataset__badge w-75 text-center">
                      <p class="badge badge-free" v-if="dataset.free === true">free</p>
                      <p class="badge badge-premium" v-else>premium</p>
                    </div>
                    <div class="description" :class="{ 'show': dataset.hovered }">
                      <div class="h-100 justify-content-center align-items-center p-2">
                        <p>{{ dataset.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
      
              </div>
            </div>
            <div class="mt-5">
              <a href="/datasets" class="btn btn-primary" style="z-index: 50;">View all datasets</a>
            </div>
          </div>
          <hr />
          <div>
            <h2><strong>Dashboard</strong></h2>
              <DashBoardItem :dashboards="dashboards" />
            <div class="mt-5">
              <a href="/dashboards" class="btn btn-primary" style="z-index: 50;">View all dashboards</a>
            </div>
          </div>
    </div>
</template>


<script>
import api from '@/services/api'
import DashBoardItem from '../components/Dashboard/DashboardItem.vue';
import NewsCard from '../components/Datalab/NewsCard.vue';

export default {
  components: {
    DashBoardItem,
    NewsCard
  },
  data() {
    return {
      datasets: [],
      filteredDatasets: [],
      dashboards: {},
      cards: [
        {
          text: "Update of Foreign Exchange database (fx), TAC ECONOMICS projections for exchange on a wide range of countries",
          date: "Nov. 18, 2024",
          links: [
            { href: "/search?dataset=fx", label: "View fx dataset" },
            { href: "/dashboards/6", label: "View FX Forecast dashboard" }
          ]
        },
        {
          text: "Update of Short-Term Forecasts (stf), TAC ECONOMICS projections for GDP Growth, Inflation, Policy Rate",
          date: "Nov. 18, 2024",
          links: [
            { href: "/search?dataset=stf", label: "View stf dataset" },
            { href: "/dashboards/12", label: "View 10Key EM GDP Growth Forecast dashboard" },
            { href: "/dashboards/5", label: "View G4 Forecasts dashboard" }
          ]
        },
        {
          text: "Update of Business Cycle database (buscycle), nowcast of the business cycle position for Euro Area and United States",
          date: "Nov. 18, 2024",
          links: [
            { href: "/search?dataset=buscycle", label: "View buscycle dataset" },
            { href: "/dashboards/13", label: "View Key Quarterly Indicators" }
          ]
        }
      ],
      checked: {
        free: true,
        premium: true,
      }
    };
  },
  methods: {
    async load() {
      await api.get("/data/datasets").then(response => {
        this.datasets = response.data.data;
        // this.filteredDatasets = this.datasets.filter(item => 
        //   item.id !== 'bmt' && item.id !== 'unctad_international_trade' && item.id !== 'dots' && item.id !== 'cepii' && item.id !== 'geopol'
        // );
        this.filteredDatasets = this.datasets.filter(item => 
          item.id == 'countryrisk' || item.id == 'ipd' || item.id == 'ngfs' || item.id == 'stf' || item.id == 'vdem' || item.id == 'weo'
        );
        // this.filteredDatasets = this.filteredDatasets.slice(0, 6);
      });
      await api.get("/dashboards/")
        .then(response => {
          this.dashboards = response.data;
          this.dashboards = this.dashboards.filter(item => 
            item.id == '13' || item.id == '5' || item.id == '6' || item.id == '15' || item.id == '12'
          );
          // this.dashboards = this.dashboards.slice(0, 5);
        })
        .catch((error) => {
          this.code_access = error.response.status;
       });
    },
    onFilterChange() {
      this.filteredDatasets = this.datasets.filter(dataset => {
        if (this.checked.free && this.checked.premium) {
          return true;
        } else if (this.checked.free) {
          return dataset.free === true;
        } else if (this.checked.premium) {
          return dataset.free === false;
        }
        return false;
      });
    }
  },
  async mounted() {
    await this.load();
  }
};

</script>

<style scoped>

.title {
  color: rgb(0, 0, 0);
  font-size: 1.4em;
  position: relative;
  z-index: 8;
  font-family: 'Poppins';
}

.badge-popular {
  text-decoration: none;
  font-size: 0.9rem;
  background-color: rgba(233, 233, 233, 0.35);
  border-radius: 30px;
  color: rgb(26, 26, 26);
  padding: 1em;
  font-weight: 500;
  margin: 0.2rem;
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 8;
  font-family: 'Poppins';
}

.search {
  width: 100%;
}

.search input {
  padding: 0.7em 2em;
  border-radius: 30px;
  font-size: 1.2em;
  transition: all 0.2s ease-in-out;
  border: none;
  color: rgb(0, 0, 0);
  background-color: rgba(240, 240, 240, 0.651);
}

.search input::placeholder {
  transition: all 0.2s ease-in-out;
  font-family: 'Poppins';
}

.search input:hover {
  background-color: rgba(235, 235, 235, 0.891);
}

.search input:focus {
  background-color: rgba(255, 255, 255, 0.27);
}

.search input:hover::placeholder {
  color: black;
}

.search input:focus::placeholder {
  color: white;
}

.search button i {
  font-size: 1.2em;
}

.search button {
  width: 7%;
  border: none;
  background-color: rgba(240, 240, 240, 0.651);
  border-top-right-radius: 30px;
  border-end-end-radius: 30px;
  transition: all 0.2s ease-in-out;
}

.search button:hover {
  background-color: rgba(230, 230, 230, 0.891);
}

.search button:active {
  background-color: rgba(214, 214, 214, 0.891);
}

.blocDatasets {
  padding: 0 10%;
}

.blockDataset {
  height: 250px;
  border-radius: 10px;
  padding: 1em;
  transition: all 0.3s ease-in-out;
  border: solid 2px rgb(222, 222, 222);
  box-shadow: 0 1px 3px grey;
}

.blockDataset:hover {
  color: white;
  background-color: #031f85;
  border-color: #031f85;
}

.blockDataset:hover .blockDataset__badge, .blockDataset:hover .card-title, .blockDataset:hover .card-subtitle, .blockDataset:hover hr {
  transform: translateY(-10px);
}

.blockDataset:hover .blockDataset__badge {
  padding: 0 1em;
}

.blockDataset:hover hr {
  border-color: white;
}

.blockDataset:active {
  opacity: 0.7;
  transition: all 0.1s ease-in-out;
}

.blockDataset hr {
  width: 80%;
  border: 1px solid black;
  transition: all 0.2s ease-in-out;
}

.card-title {
  color: var(--te-color-title);
  font-weight: 600;
  font-size: 1.0rem;
  transition: all 0.2s ease-in-out;
}

.card-subtitle {
  transition: all 0.2s ease-in-out;
}

.blockDataset__badge {
  padding: 1em;
  transition: all 0.2s ease-in-out;
}

.title-hover {
  color: white;
}

.description {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  font-size: 0.8em;
  text-align: justify;
}

.show {
  opacity: 1;
  transform: translateY(0);
}
</style>
